import { AxiosResponse } from 'axios'

import { IDesktopUrlDefinitionResponse } from '~/api/dataTypes/define'
import { request } from '~/api/requests/axios'
import { IGetDefineParams } from '~/api/requests/types'

export const getDefine = async (params: IGetDefineParams): Promise<AxiosResponse<IDesktopUrlDefinitionResponse>> => {
	const {
		url,
		isInternal = false,
		isTurbine = false,
	} = params

	return await request.get(`/v1/define?url=${ url }`, {
		service: 'WEB_TOOLS_SERVICE',
		isInternal,
		isTurbine,
	})
}
