import { useCallback, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useToggle } from 'react-use'
import { AxiosResponse } from 'axios'
import { isEqual, isNull, isUndefined } from 'lodash'

import { getAuthenticateToken } from '~/actions/auth'
import { getCartSimpleData, setCartStepZeroData } from '~/actions/cart'
import { postCartIdentificationData } from '~/actions/cartIdentification'
import { getCustomerData, getCustomerLastViewedProducts } from '~/actions/customer'
import { getFavouriteListSimpleData } from '~/actions/favouriteList'
import { IHandleRedirectAfterSignInParams, ISignInFormData, IUseSignInData, IUseSignInParams } from '~/hooks/signIn'
import { useCartStatus } from '~/hooks/cartStatus'
import { useGoogleAnalytics } from '~/hooks/googleAnalytics'
import { useLogError } from '~/hooks/logError'
import { IRootState } from '~/state/types'
import { AppDispatch } from '~/state/store'
import { isUserLogged } from '~/state/reducers/authReducer'
import { getCustomerPreferredStore } from '~/actions/preferredStore'
import { CartStatusType, ICartIdentificationParameters, ICartIdentificationResponse } from '~/api/dataTypes/cart'
import { ICustomerResult } from '~/api/dataTypes/customer'
import { postCartIdentification } from '~/api/requests/cart'
import { getDefine } from '~/api/requests/tool'
import { CalendarServiceCartStatusType, ICalendarIdentificationResponse } from '~/api/dataTypes/calendarService'
import { postCalendarIdentification } from '~/api/requests/calendarService'
import { postServiceCartIdentificationData } from '~/actions/serviceCartIdentification'
import { useServiceCartStatus } from '~/hooks/serviceCartStatus'

export const useSignIn = (params: IUseSignInParams = {}): IUseSignInData => {
	const {
		retryLimit = 10,
		isFromCart = false,
		isFromServiceCart = false,
		retryAfter = 1000,
	} = params
	const isLogged = useSelector((state: IRootState) => isUserLogged(state))
	const { cartUuid } = useSelector((state: IRootState) => state.miniCart.data, shallowEqual)
	const { calendarCartUuid } = useSelector((state: IRootState) => state.cartIdentification, shallowEqual)
	const dispatch: AppDispatch = useDispatch()
	const router = useRouter()
	const { GA_login } = useGoogleAnalytics()
	const { changeCartView } = useCartStatus()
	const { changeServiceCartView } = useServiceCartStatus()
	const intervalRef = useRef<NodeJS.Timer | null>(null)
	const [isPending, setIsPending] = useToggle(false)
	const [credentials, setCredentials] = useState<ISignInFormData | null>(null)
	const retryLimitRef = useRef<number>(retryLimit)
	const { sendLogError } = useLogError()

	const clearRequestInterval = useCallback((): void => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current)
			intervalRef.current = null
			setIsPending(false)
		}
	}, [])

	const getCartIdentification = useCallback(async (): Promise<ICartIdentificationResponse | ICalendarIdentificationResponse | undefined> => {
		try {
			let data: ICartIdentificationResponse | ICalendarIdentificationResponse | undefined
			const isNotFromCartAndServiceCart = !isFromCart && !isFromServiceCart

			const params: ICartIdentificationParameters = {
				cartUuid,
				calendarCartUuid,
			}

			if (isFromCart || isNotFromCartAndServiceCart) {
				const { data: dataFromCartIdentification } = await postCartIdentification(params)
				data = dataFromCartIdentification

				await dispatch(postCartIdentificationData(data))
			}

			if (isFromServiceCart) {
				const { data: dataFromCalendarCartIdentification } = await postCalendarIdentification(params)
				data = dataFromCalendarCartIdentification

				await dispatch(postServiceCartIdentificationData(data))
			}

			return data
		} catch (e: unknown) {}
	}, [])

	const handleAuthenticateUser = useCallback(async (): Promise<void> => {
		retryLimitRef.current = retryLimitRef.current - 1

		if (retryLimitRef.current < 1) {
			clearRequestInterval()
		}

		try {
			if (!isNull(credentials)) {
				await dispatch(getAuthenticateToken(credentials))
			}
		} catch (e: unknown) {}
	}, [credentials])

	const onCustomerSignIn = useCallback(async (formData: ISignInFormData): Promise<void> => {

		setIsPending(true)

		try {
			await dispatch(getAuthenticateToken(formData))
		} catch (e: unknown) {
			setCredentials(formData)
		}
	}, [])

	const handleSignIn = useCallback(async (formData: ISignInFormData): Promise<void | unknown> => {
		try {
			await dispatch(getAuthenticateToken({ ...formData }))
		} catch (e: unknown) {
			return Promise.reject(e)
		}
	}, [])

	const handleFetchDataAfterSignIn = useCallback(async (): Promise<ICartIdentificationResponse | ICalendarIdentificationResponse | undefined> => {
		try {
			const { value }: { value: AxiosResponse<ICustomerResult> } = await dispatch(getCustomerData())

			GA_login(value.data.customer.customerNumber)

			const cartIdentificationResponse = await getCartIdentification()

			await dispatch(getCartSimpleData())
			await dispatch(getFavouriteListSimpleData())
			await dispatch(getCustomerLastViewedProducts())

			if (!isFromCart && !isFromServiceCart) {
				await dispatch(getCustomerPreferredStore())
			}

			if (isFromCart) {
				await dispatch(setCartStepZeroData(!isFromCart))
			}

			return cartIdentificationResponse
		} catch (e: unknown) {
			sendLogError(e)
		}
	}, [isFromCart, isFromServiceCart])

	const handleRedirectAfterSignIn = useCallback(async (params: IHandleRedirectAfterSignInParams = {}): Promise<void | undefined> => {
		const { cartIdentification } = params
		const redirectUrl = (router.query.from as string) ?? '/'
		const isHtmlLink = isEqual(router.query.isHtml, 'true')

		try {
			if (isFromCart && !isUndefined(cartIdentification)) {
				await changeCartView(2, cartIdentification.cartStatus as CartStatusType)

				return
			}

			if (isFromServiceCart && !isUndefined(cartIdentification)) {
				await changeServiceCartView(2, cartIdentification.cartStatus as CalendarServiceCartStatusType)

				return
			}

			const url = isHtmlLink ? `${ redirectUrl }.html` : redirectUrl
			const { data: { exists } } = await getDefine({ url })

			await router.push(exists ? url : '/')
		} catch (e: unknown) {}
	}, [isFromCart, isFromServiceCart])

	useEffect(() => {
		if (isPending) {
			intervalRef.current = setInterval(handleAuthenticateUser, retryAfter)
		}
	}, [isPending, handleAuthenticateUser])

	useEffect(() => {
		if (isLogged && isPending) {
			(async () => {
				const cartIdentification = await handleFetchDataAfterSignIn()

				await handleRedirectAfterSignIn({ cartIdentification })
			})()

			clearRequestInterval()
		}
	}, [isLogged, isPending])

	useEffect(() => {
		return (() => {
			clearRequestInterval()
		})
	}, [])

	return {
		onCustomerSignIn,
		handleSignIn,
		handleFetchDataAfterSignIn,
		handleRedirectAfterSignIn,
		isPending,
		isLogged,
	}
}
