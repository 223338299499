import { AxiosResponse } from 'axios'

import { ISimpleStoreResponse, IStoreResponse, IStoreAllServicesResponse, IStoreServiceResponse, IStoreSimpleResultResponse } from '~/api/dataTypes/store'
import { request } from '~/api/requests/axios'
import { IGetAllStoreServicesParams, IGetStoreParams, IGetStoreServiceParams, IGetStoresParams } from '~/api/requests/types'
import { getNoCacheParam } from '~/utils/urls'

export const getStores = async (params: IGetStoresParams = {}): Promise<AxiosResponse<ISimpleStoreResponse[]>> => {
	const {
		isInternal = false,
		noCache = '',
	} = params

	return await request.get('/v1/store', {
		isInternal,
		params: getNoCacheParam(noCache),
	})
}

export const getStore = async (params: IGetStoreParams): Promise<AxiosResponse<IStoreResponse>> => {
	const {
		storeCode,
		isInternal = false,
		noCache = '',
	} = params

	return await request.get(`/v1/store/${ storeCode }`, {
		isInternal,
		params: getNoCacheParam(noCache),
	})
}

export const getAllStoreServices = async (params: IGetAllStoreServicesParams): Promise<AxiosResponse<IStoreAllServicesResponse>> => {
	const {
		noCache,
		isTurbine = false,
	} = params

	return await request.get('/v1/store/services', {
		isInternal: true,
		isTurbine,
		params: getNoCacheParam(noCache),
	})
}

export const getStoreService = async (params: IGetStoreServiceParams): Promise<AxiosResponse<IStoreServiceResponse>> => {
	const {
		storeCode,
		noCache = '',
		isTurbine = false,
	} = params

	return await request.get(`/v1/store/services/${ storeCode }`, {
		isInternal: true,
		params: getNoCacheParam(noCache),
		isTurbine,
	})
}

export const getStoresSimpleList = async (): Promise<AxiosResponse<IStoreSimpleResultResponse[]>> => (
	await request.get('/public/v1/published-stores', {
		service: 'STORE_DATA_SERVICE',
	})
)
